(function() {
  'use strict';

  angular
    .module('imageServerClient', ['ngAnimate', 'ngCookies', 'ngTouch', 'ngSanitize', 'ngMessages', 'ngResource', 'ui.router', 'ngMaterial', 'toastr', 'angularFileUpload']);

})();

(function() {
  'use strict';

  angular
      .module('imageServerClient')
      .service('webDevTec', webDevTec);

  /** @ngInject */
  function webDevTec() {
    var data = [
      {
        'title': 'AngularJS',
        'url': 'https://angularjs.org/',
        'description': 'HTML enhanced for web apps!',
        'logo': 'angular.png'
      },
      {
        'title': 'BrowserSync',
        'url': 'http://browsersync.io/',
        'description': 'Time-saving synchronised browser testing.',
        'logo': 'browsersync.png'
      },
      {
        'title': 'GulpJS',
        'url': 'http://gulpjs.com/',
        'description': 'The streaming build system.',
        'logo': 'gulp.png'
      },
      {
        'title': 'Jasmine',
        'url': 'http://jasmine.github.io/',
        'description': 'Behavior-Driven JavaScript.',
        'logo': 'jasmine.png'
      },
      {
        'title': 'Karma',
        'url': 'http://karma-runner.github.io/',
        'description': 'Spectacular Test Runner for JavaScript.',
        'logo': 'karma.png'
      },
      {
        'title': 'Protractor',
        'url': 'https://github.com/angular/protractor',
        'description': 'End to end test framework for AngularJS applications built on top of WebDriverJS.',
        'logo': 'protractor.png'
      },
      {
        'title': 'Angular Material Design',
        'url': 'https://material.angularjs.org/#/',
        'description': 'The Angular reference implementation of the Google\'s Material Design specification.',
        'logo': 'angular-material.png'
      }
    ];

    this.getTec = getTec;

    function getTec() {
      return data;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .directive('acmeNavbar', acmeNavbar);

  /** @ngInject */
  function acmeNavbar() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/components/navbar/navbar.html',
      scope: {
          creationDate: '='
      },
      controller: NavbarController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function NavbarController(moment) {
      var vm = this;

      // "vm.creation" is avaible by directive option "bindToController: true"
      vm.relativeDate = moment(vm.creationDate).fromNow();
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .directive('acmeMalarkey', acmeMalarkey);

  /** @ngInject */
  function acmeMalarkey(malarkey) {
    var directive = {
      restrict: 'E',
      scope: {
        extraValues: '='
      },
      template: '&nbsp;',
      link: linkFunc,
      controller: MalarkeyController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {
      var watcher;
      var typist = malarkey(el[0], {
        typeSpeed: 40,
        deleteSpeed: 40,
        pauseDelay: 800,
        loop: true,
        postfix: ' '
      });

      el.addClass('acme-malarkey');

      angular.forEach(scope.extraValues, function(value) {
        typist.type(value).pause().delete();
      });

      watcher = scope.$watch('vm.contributors', function() {
        angular.forEach(vm.contributors, function(contributor) {
          typist.type(contributor.login).pause().delete();
        });
      });

      scope.$on('$destroy', function () {
        watcher();
      });
    }

    /** @ngInject */
    function MalarkeyController($log, githubContributor) {
      var vm = this;

      vm.contributors = [];

      activate();

      function activate() {
        return getContributors().then(function() {
          $log.info('Activated Contributors View');
        });
      }

      function getContributors() {
        return githubContributor.getContributors(10).then(function(data) {
          vm.contributors = data;

          return vm.contributors;
        });
      }
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .factory('githubContributor', githubContributor);

  /** @ngInject */
  function githubContributor($log, $http) {
    var apiHost = 'https://api.github.com/repos/Swiip/generator-gulp-angular';

    var service = {
      apiHost: apiHost,
      getContributors: getContributors
    };

    return service;

    function getContributors(limit) {
      if (!limit) {
        limit = 30;
      }

      return $http.get(apiHost + '/contributors?per_page=' + limit)
        .then(getContributorsComplete)
        .catch(getContributorsFailed);

      function getContributorsComplete(response) {
        return response.data;
      }

      function getContributorsFailed(error) {
        $log.error('XHR Failed for getContributors.\n' + angular.toJson(error.data, true));
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .service('ImageServerService', ImageServerService);

    /** @ngInject */
    function ImageServerService($http, ImageServer){
      this.getCollections = function(){
        return $http.get(ImageServer.url + 'collection');
      }

      this.getSubcollections = function(collection) {
        return $http.get(ImageServer.url + 'collection/' + collection);
      }

      this.getImagesFromCollection = function(collection) {
        return $http.get(ImageServer.url + 'collection/' + collection);
      }

      this.deleteImage = function(imageUrl) {
        var imagePath = imageUrl.replace(ImageServer.url, '');
        return $http.get(ImageServer.url + 'del/' + imagePath);
      }

      this.deleteCollection = function(collection) {
        return $http.get(ImageServer.url + 'delete_collection/' + collection);
      }

    }

})();

(function() {
  'use strict';
  console.log('controller');

  angular
    .module('imageServerClient')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController($scope, $timeout, webDevTec, toastr, FileUploader, ImageServer, ImageServerService) {
    var vm = this;

    activate();

    $scope.collections = [];
    $scope.subcollections = [];
    $scope.collectionImages = [];
    $scope.selectedCollection = "frames";

    function getCollections() {
      ImageServerService.getCollections().success(function(result){
        $scope.collections = result;
      });
    }

    $scope.selectCollection = function(collection){
      $scope.selectedCollection = collection;
      onCollectionChanged();
    }

    $scope.selectSubcollection = function(collection){
      $scope.selectedSubcollection = collection;
      onSubcollectionChanged();
    }

    function onCollectionChanged(){
      var collection = $scope.selectedCollection;
      ImageServerService.getSubcollections(collection)
      .success(function(subcollections){
        $scope.subcollections = subcollections;
      });
    }

    function onSubcollectionChanged() {
      var url = ImageServer.uploadURL + $scope.selectedCollection;
      if($scope.selectedSubcollection) {
        url = url + '/' + $scope.selectedSubcollection;
      }
      $scope.uploader.url = url;
      updateCollectionImages();
    }

    function updateCollectionImages() {
      var url = $scope.selectedCollection;
      if($scope.selectedSubcollection) {
        url = url + '/' + $scope.selectedSubcollection;
      }
      console.log('url', url);
      ImageServerService.getImagesFromCollection(url)
      .then(function(response){
          $scope.collectionImages = response.data.map(function(image){
            return ImageServer.url + image;
          });
      }, function(response){
        $scope.collectionImages = [];
      });
    }



    $scope.upload = function(item){
      item.upload();
    }

    $scope.deleteImage = function(url){
      console.log('delete ' + url);
      ImageServerService.deleteImage(url)
      .success(function(){
        updateCollectionImages();
      });
    }

    $scope.addCollection = function(newCollection) {
      if(newCollection){
          $scope.collections.push(newCollection);
          $scope.newCollection = '';
      }
    }

    $scope.addSubcollection = function(newSubcollection){
      if(newSubcollection){
        $scope.subcollections.push(newSubcollection);
        $scope.newSubcollection = '';
      }
    }

    $scope.deleteCollection = function(collection) {
      console.log('delete ' + collection);
      ImageServerService.deleteCollection(collection)
      .success(function(){
          onCollectionChanged();
          onSubcollectionChanged();
      });
    }

    $scope.uploader = new FileUploader({
      url: ImageServer.uploadURL + $scope.selectedCollection,
      autoUpload: true
    });

    $scope.uploader.onSuccessItem = function(){
      updateCollectionImages();
    };

    function activate() {
      getCollections();
    }

  }
})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log) {

    $log.debug('runBlock end');
  }

})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'app/main/main.html',
        controller: 'MainController',
        controllerAs: 'main'
      });

    $urlRouterProvider.otherwise('/');
  }

})();

/* global malarkey:false, moment:false */
(function() {
  'use strict';

  var url = location.origin + '/';
  var uploadURL = location.origin + '/upload/';

  // var url = 'http://localhost:8080/';
  // var uploadURL = url + 'upload/';

  angular
    .module('imageServerClient')
    .constant('malarkey', malarkey)
    .constant('moment', moment)
    .constant('ImageServer', {
      url: url,
      uploadURL: uploadURL
    });

})();

(function() {
  'use strict';

  angular
    .module('imageServerClient')
    .config(config);

  /** @ngInject */
  function config($logProvider, toastrConfig) {
    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;
  }

})();

angular.module("imageServerClient").run(["$templateCache", function($templateCache) {$templateCache.put("app/main/main.html","<div layout=\"column\" layout-fill=\"\"><md-content><div layout=\"row\"><div ng-repeat=\"collection in collections\" layout=\"column\"><md-button ng-click=\"selectCollection(collection);\">{{collection}}</md-button><md-button ng-click=\"deleteCollection(collection);\">X</md-button></div><md-input-container class=\"md-block\"><label>New Collection</label> <input ng-model=\"newCollection\"></md-input-container><md-button ng-click=\"addCollection(newCollection)\">Add</md-button></div></md-content><md-content><div layout=\"row\"><div ng-repeat=\"subcollection in subcollections\" layout=\"column\"><md-button ng-click=\"selectSubcollection(subcollection);\">{{subcollection}}</md-button><md-button ng-click=\"deleteCollection(selectedCollection + \'/\' +subcollection);\">X</md-button></div><md-input-container class=\"md-block\"><label>New Subcollection</label> <input ng-model=\"newSubcollection\"></md-input-container><md-button ng-click=\"addSubcollection(newSubcollection)\">Add</md-button></div></md-content><md-content><h3>{{selectedCollection + \'/\' + selectedSubcollection}}</h3></md-content><md-content><div layout=\"row\" layout-margin=\"\" layout-align=\"left center\"><div layout=\"column\" class=\"image-box\" ng-repeat=\"image in collectionImages\"><img class=\"margin\" ng-src=\"{{image}}\" height=\"150\"><md-button ng-click=\"deleteImage(image);\">X</md-button></div></div></md-content><md-content><input type=\"file\" nv-file-select=\"\" uploader=\"uploader\"><br></md-content></div>");
$templateCache.put("app/components/navbar/navbar.html","<md-toolbar layout=\"row\" layout-align=\"center center\"><md-button href=\"https://github.com/Swiip/generator-gulp-angular\">Gulp Angular</md-button><section flex=\"\" layout=\"row\" layout-align=\"left center\"><md-button href=\"#\" class=\"md-raised\">Home</md-button><md-button href=\"#\" class=\"md-raised\">About</md-button><md-button href=\"#\" class=\"md-raised\">Contact</md-button></section><md-button class=\"acme-navbar-text\">Application was created {{ vm.relativeDate }}.</md-button></md-toolbar>");}]);